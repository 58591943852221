<template>
  <div class="access-management" ref="accessManagement">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="所属企业：">
          <companySelect
            :clearable="true"
            :companyTree="companyList"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="onAddAccess"
            v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
            >新增规则</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      :height="tableHeight"
      border
      empty-text="暂无数据"
    >
      <!-- <el-table-column
        prop="serialNumber"
        label="序号"
        width="80"
      ></el-table-column> -->
      <el-table-column
        prop="companyName"
        label="公司名称"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="makerId"
        label="生产厂家名"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ producerObj[Number(scope.row.makerId)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceNum"
        label="可接入"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="deviceUsedNum"
        label="已接入"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column label="剩余" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.deviceNum - scope.row.deviceUsedNum }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateAccess(scope.row)"
            type="text"
            size="small"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
            @click="onDeleteAccess(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!--新增 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <add-access
        ref="addAccess"
        :rowData="rowData"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></add-access>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import companySelect from '@/components/treeSelect/companySelect.vue'
import { formatDict } from '@/common/utils/index'
import {
  queryCompanyDeviceList,
  queryDictsByCodes,
  deleteCompanyDevice,
  getBusinessCompanyTree
} from '@/api/lib/api.js'
import addAccess from './components/addAccess.vue'

export default {
  name: 'accessManagement',
  components: {
    companySelect,
    addAccess
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        companyId: null,
        currentPage: 1,
        pageSize: 10
      },
      producerList: [],
      producerObj: null,
      rowData: null,
      title: '',
      dialogVisible: false,
      searchLoading: false,
      editLoading: false,
      companyList: []
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.accessManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    // 表格合并
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      const span = 'companyId-span'
      if (columnIndex === 0) {
        if (row[span]) {
          return row[span]
        }
      }
    },
    getGroupId (v) {
      this.form.companyId = v
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    //获取数据
    getListByField () {
      let data = { ...this.form }
      queryCompanyDeviceList(data)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData =
              res.data.list && res.data.list.length > 0
                ? this.mergeTableRow(res.data.list, 'companyId')
                : []
            this.total = res.data.total
            if (res.data.list.length === 0 && data.currentPage !== 1) {
              this.form.currentPage -= 1
              this.getListByField()
            }
          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getListByField()
    },
    // 新增
    onAddAccess () {
      this.title = '新增准入规则配置'
      this.rowData = null
      this.dialogVisible = true
    },
    // 编辑
    onUpdateAccess (row) {
      this.title = '编辑准入规则配置'
      this.rowData = row
      this.dialogVisible = true
    },

    //  删除
    onDeleteAccess (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [h('span', null, '确定删除这条数据？')]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteCompanyDevice(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.getListByField()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },
    //  保存
    onSave () {
      this.$refs.addAccess.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //表格合并
    mergeTableRow (data, merge) {
      if (data.length === 0) {
        return data
      }
      const mList = {}
      data = data.map((v, index) => {
        const rowVal = v[merge]
        if (mList[rowVal] && mList[rowVal].newIndex === index) {
          mList[rowVal]['num']++
          mList[rowVal]['newIndex']++
          data[mList[rowVal]['index']][merge + '-span'].rowspan++
          v[merge + '-span'] = {
            rowspan: 0,
            colspan: 0
          }
        } else {
          mList[rowVal] = { num: 1, index: index, newIndex: index + 1 }
          v[merge + '-span'] = {
            rowspan: 1,
            colspan: 1
          }
        }
        return v
      })
      return data
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'SBCJ' }).then((res) => {
        if (res.code === 1000) {
          this.producerList = res.data.SBCJ
          this.producerObj = formatDict(this.producerList)
        }
      })
    }
  },
  created () {
    this.getDicts()
    this.getCompanyList()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.access-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}
.theme-project-resourse {
  .access-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
